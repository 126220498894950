import React from 'react'
import {Link} from 'gatsby'

const ServiceSidebarScience = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/service-details-discovery">
                        Data Discovery
                    </Link>
                </li>
                <li>
                    <Link to="/service-details-processing">
                        Data Processing
                    </Link>
                </li>
                <li>
                    <Link to="/service-details-science" className="active">
                        Data Science & Analytics
                    </Link>
                </li>
                <li>
                    <Link to="/service-details-testing">
                        Testing Frameworks
                    </Link>
                </li>
                <li>
                    <Link to="/service-details-abinitio">
                        AbInitio
                    </Link>
                </li>
            </ul>

            <div className="services-contact-info">
                <h3>Contact Info</h3>
                
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Phone:</span>
                        <a href="tel:+40723318998">+4072 331 8998</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        Bucharest, Romania
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:contact@diconsulting.ro">contact@diconsulting.ro</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebarScience