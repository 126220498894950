import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentScience from '../components/ServiceDetails/ServiceDetailsContentScience'
//import RelatedServices from '../components/ServiceDetails/RelatedServices'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Data Science & Analytics" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Details Science" 
            />
            <ServiceDetailsContentScience />
            <Footer />
        </Layout>
    );
}

export default Details