import React from 'react'
import ServiceSidebarScience from './ServiceSidebarScience'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentScience = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Data Science & Analytics Services</span>
                            <h3>About</h3>
                            <p>We support the entire process of data science & analytics from data identification, cleansing, model creations, until training and deployment.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Support</h3>
                                        <ul>
                                            <li>Python & Pandas</li>
                                            <li>Machine Learning</li>
                                            <li>Big Data Analytics</li>
                                            <li>Decision Inteligence</li>
                                            <li>Reporting Analytics</li>
                                            <li>R</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Having everything integrated from business and technical definitions until data access, it ensures easy access to machine learning, analytics and intelligent decisions making. Governing and learning from the data enlarges the effectiveness of the developed business and ensures valuable impact over the revenues.</p>
                            <p>Data driven decision-making empowers organizations to automatically score assets, correlate them, detect hidden relations and tailor the analytics.</p>
                            <p>Having the right platform and technology in the portfolio facilitates the entire process and the effectiveness of having such a department part of the organization.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarScience />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentScience